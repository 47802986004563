import invariant from 'invariant'
import { Controller } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { Spacings } from '@contracts/enums/Spacings'

import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'
import { getInternationalPhoneNumber, isValidNumber } from '@pure/libs/PhoneNumberHelper'

import { ENTER_KEYCODE } from '@web-js/libs/Consts'

import Box from '@pay/components/Box'
import FigmaImageContainer from '@pay/components/FigmaImageContainer'
import FigmaText from '@pay/components/FigmaText'
import { Layout } from '@pay/components/Layout'
import { ReceiptViewSMSButton } from '@pay/components/ReceiptButtonsComponent'
import TextFieldForm from '@pay/components/TextFieldForm'
import { useForm } from '@pay/hooks/useForm'
import { sendReceiptForSession } from '@pay/libs/CloudFunctionsApiHandler'
import { HardCodedTexts } from '@pay/libs/HardCodedTexts'

const ValidationSchema = Yup.object()
  .shape({
    phone: Yup.string().test('phoneNumber', 'Ange telefonnummer', (text) => isValidNumber(text))
  })
  .required()

export const ReceiptSendSMSPage = () => {
  const location = useLocation()
  const formProps = useForm(ValidationSchema, { defaultValues: { phone: '' } })

  const onSubmit = formProps.handleSubmit(({ phone }) => {
    const sessionId = location?.state?.session?.id
    invariant(sessionId, '!sessionId')
    const phoneWithInternationPrefix = getInternationalPhoneNumber(phone)
    return sendReceiptForSession({ sessionId, phone: phoneWithInternationPrefix })
  })

  const navigate = useNavigate()

  return (
    <Layout>
      <Box fullWidth fullPadding>
        <Box onClick={() => navigate(-1)} pointer>
          <FigmaImageContainer imageKey={Images.arrowLeftGrey} />
        </Box>
        <Box top>
          <FigmaText textKey={HardCodedTexts.textPaymentsSwishEnterPhoneNumber} />
        </Box>
        <Box top spacing={Spacings.xs} fullWidth>
          <Controller
            control={formProps.control}
            name="phone"
            render={({ field, fieldState }) => {
              return (
                <TextFieldForm
                  {...field}
                  fullWidth
                  fieldState={fieldState}
                  onKeyDown={(e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)}
                  autoFocus
                  placeholderTextKey={Texts.textPaymentsSwishEnterPhoneNumberHelper}
                />
              )
            }}
          />
        </Box>
        <Box top fullWidth>
          <ReceiptViewSMSButton onClick={onSubmit} />
        </Box>
      </Box>
    </Layout>
  )
}
