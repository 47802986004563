import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Button, Container, IconButton, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useIsDesktop } from '@pay/hooks/useIsMobile'
import { sendPermitUsersForm } from '@pay/libs/CloudFunctionsApiHandler'

import { LayoutHeaderApp, LayoutHeaderDesktop } from './LayoutHeaderApp'

const DefaultPermitFormUser = {
  name: '',
  email: '',
  phone: ''
}

const DefaultPermitUsersFormData = {
  orgName: '',
  area: '',
  numberOfPermits: '1',
  users: [DefaultPermitFormUser]
}

export default function PermitUsersForm() {
  const isDesktop = useIsDesktop()
  const LayoutHeader = isDesktop ? LayoutHeaderDesktop : LayoutHeaderApp
  const navigate = useNavigate()

  const [formData, setFormData] = useState(DefaultPermitUsersFormData)

  const [errors, setErrors] = useState(DefaultPermitUsersFormData)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
    const { name, value } = e.target

    if (index === undefined) {
      setFormData({ ...formData, [name]: value })
    } else {
      const updatedUsers = formData.users.map((user, i) => (i === index ? { ...user, [name]: value } : user))
      setFormData({ ...formData, users: updatedUsers })
    }
  }

  const validate = () => {
    const newErrors = { ...errors }
    let isValid = true

    if (!formData.orgName) {
      newErrors.orgName = 'Företagsnamn krävs'
    } else {
      newErrors.orgName = ''
    }

    if (!formData.area) {
      newErrors.area = 'Adress krävs'
    } else {
      newErrors.area = ''
    }

    newErrors.users = formData.users.map((user) => {
      const userErrors = { name: '', email: '', phone: '', plate: '' }
      if (!user.name) {
        userErrors.name = 'Namn krävs'
        isValid = false
      }
      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(user.email)) {
        userErrors.email = 'Ogiltig epost'
        isValid = false
      }
      if (!/^\+46\s?\d{6,12}$/.test(user.phone)) {
        userErrors.phone = 'Ogiltigt telefonnummer (använd format +46)'
        isValid = false
      }
      return userErrors
    })

    setErrors(newErrors)
    return isValid
  }

  const addUserRow = () => {
    setFormData({
      ...formData,
      users: [...formData.users, DefaultPermitFormUser]
    })
    setErrors({
      ...errors,
      users: [...errors.users, DefaultPermitFormUser]
    })
  }

  const removeUserRow = () => {
    if (formData.users.length > 1) {
      setFormData({
        ...formData,
        users: [...formData.users.splice(-1)]
      })
      setErrors({
        ...errors,
        users: [...errors.users.splice(-1)]
      })
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validate()) {
      sendPermitUsersForm(formData)
      navigate('/permit-success')
    }
  }

  return (
    <>
      <LayoutHeader onClickHeaderLogo={() => {}}></LayoutHeader>
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h5">
            Månadstillstånd i bolagen Nyfosa Samuel 1 Fastighets AB och Nyfosa Sigvald 6 Fastighets AB
          </Typography>
          <Typography component="h3" variant="h6">
            Fastighetsbeteckning <br />
            Samuel 1, Sigurdsgatan 2-25 <br />
            Sigvald 6, Sigurdsgatan 7 och Sigurdsgatan 9<br />
            Sigvald 5, Sigurdsgatan 11 <br />
            Sigurd 5, Sigurdsgatan 21 <br />
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="Företagsnamn/Namn"
              name="orgName"
              variant="outlined"
              value={formData.orgName}
              onChange={handleChange}
              error={!!errors.orgName}
              helperText={errors.orgName}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Ort"
              name="area"
              variant="outlined"
              value={formData.area}
              onChange={handleChange}
              error={!!errors.area}
              helperText={errors.area}
              margin="normal"
            />
            <TextField
              type="number"
              fullWidth
              label="Antal tillstånd"
              name="numberOfPermits"
              variant="outlined"
              value={formData.numberOfPermits}
              onChange={handleChange}
              margin="normal"
            />

            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
              Användare
            </Typography>

            <IconButton onClick={addUserRow} color="primary" aria-label="Add User Row">
              <AddIcon />
            </IconButton>
            <IconButton onClick={removeUserRow} color="primary" aria-label="Remove User Row">
              <RemoveIcon />
            </IconButton>

            {formData.users.map((user, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="body1">{index + 1}</Typography>
                <TextField
                  fullWidth
                  label="Namn"
                  name="name"
                  variant="outlined"
                  value={user.name}
                  onChange={(e) => handleChange(e, index)}
                  error={!!errors.users[index]?.name}
                  helperText={errors.users[index]?.name}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="Epost"
                  name="email"
                  variant="outlined"
                  value={user.email}
                  onChange={(e) => handleChange(e, index)}
                  error={!!errors.users[index]?.email}
                  helperText={errors.users[index]?.email}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="Telefon"
                  name="phone"
                  variant="outlined"
                  value={user.phone}
                  onChange={(e) => handleChange(e, index)}
                  error={!!errors.users[index]?.phone}
                  helperText={errors.users[index]?.phone}
                  margin="normal"
                />
              </Box>
            ))}
            <Box></Box>
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
              Skicka
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}
