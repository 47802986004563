import { Box, Button, Container, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useIsDesktop } from '@pay/hooks/useIsMobile'
import { sendPermitForm } from '@pay/libs/CloudFunctionsApiHandler'

import { LayoutHeaderApp, LayoutHeaderDesktop } from './LayoutHeaderApp'

const DefaultPermitFormData = {
  orgNumber: '',
  orgName: '',
  address: '',
  billingEmail: '',
  phone: '',
  email: ''
}

export default function PermitRegistrationForm() {
  const isDesktop = useIsDesktop()
  const LayoutHeader = isDesktop ? LayoutHeaderDesktop : LayoutHeaderApp
  const navigate = useNavigate()

  const [formData, setFormData] = useState(DefaultPermitFormData)

  const [errors, setErrors] = useState(DefaultPermitFormData)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
    const { name, value } = e.target

    if (index === undefined) {
      setFormData({ ...formData, [name]: value })
    }
  }

  const validate = () => {
    const newErrors = { ...errors }
    const isValid = true

    if (!formData.orgNumber) {
      newErrors.orgNumber = 'Organisationsnummer/Personnummer krävs'
    } else {
      newErrors.orgNumber = ''
    }

    if (!formData.orgName) {
      newErrors.orgName = 'Företagsnamn krävs'
    } else {
      newErrors.orgName = ''
    }

    if (!formData.address) {
      newErrors.address = 'Adress krävs'
    } else {
      newErrors.address = ''
    }

    if (!formData.billingEmail) {
      newErrors.billingEmail = 'E-postadress för E-faktura krävs'
    } else {
      newErrors.billingEmail = ''
    }

    if (!/^\+46\s?\d{6,12}$/.test(formData.phone)) {
      newErrors.phone = 'Ogiltigt telefonnummer (använd format +46)'
    } else {
      newErrors.phone = ''
    }

    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = 'Ogiltig epost'
    } else {
      newErrors.email = ''
    }

    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validate()) {
      sendPermitForm(formData)
      navigate('/permit-success')
    }
  }

  return (
    <>
      <LayoutHeader onClickHeaderLogo={() => {}}></LayoutHeader>
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h5">
            Månadstillstånd i bolagen Nyfosa Samuel 1 Fastighets AB och Nyfosa Sigvald 6 Fastighets AB
          </Typography>
          <Typography component="h3" variant="h6">
            Fastighetsbeteckning <br />
            Samuel 1, Sigurdsgatan 2-25 <br />
            Sigvald 6, Sigurdsgatan 7 och Sigurdsgatan 9<br />
            Sigvald 5, Sigurdsgatan 11 <br />
            Sigurd 5, Sigurdsgatan 21 <br />
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="Organisationsnummer/Personnummer"
              name="orgNumber"
              variant="outlined"
              value={formData.orgNumber}
              onChange={handleChange}
              error={!!errors.orgNumber}
              helperText={errors.orgNumber}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Företagsnamn/Namn"
              name="orgName"
              variant="outlined"
              value={formData.orgName}
              onChange={handleChange}
              error={!!errors.orgName}
              helperText={errors.orgName}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Adress"
              name="address"
              variant="outlined"
              value={formData.address}
              onChange={handleChange}
              error={!!errors.address}
              helperText={errors.address}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Epost för E-faktura"
              name="billingEmail"
              variant="outlined"
              value={formData.billingEmail}
              onChange={handleChange}
              error={!!errors.billingEmail}
              helperText={errors.billingEmail}
              margin="normal"
            />
            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
              Kontaktperson
            </Typography>
            <TextField
              fullWidth
              label="Telefon"
              name="phone"
              variant="outlined"
              value={formData.phone}
              onChange={handleChange}
              error={!!errors.phone}
              helperText={errors.phone}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Epost"
              name="email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              margin="normal"
            />

            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
              Skicka
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}
